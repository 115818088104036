'use strict'; 
//  Author: AdminDesigns.com
// 
//  This file is reserved for changes made by the user 
//  as it's often a good idea to seperate your work from 
//  the theme. It makes modifications, and future theme
//  updates much easier 
// 

//  Place custom scripts below this line 
///////////////////////////////////////



